import LocationMarkerIcon from "./icons/LocationMarkerIcon";

const LocationMarker = () => (
    <a
        href="/#map"
        className="text-violet-500 inline-flex space-x-0.5 items-center align-bottom"
    >
        <LocationMarkerIcon/>
        <span className="font-bold">Buxtehude</span>
    </a>
);

export default LocationMarker;

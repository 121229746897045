import {Router, Switch, Route} from "react-router-dom";
import {createBrowserHistory} from "history";
import HomePage from '../pages/Home';
import MenuPage from '../pages/MenuPage';
import Footer from "../components/Footer";
import Imprint from "../pages/Imprint";

const history = createBrowserHistory({});

const App = () => (
    <div className="font-light">
        <Router history={history}>
            <Switch>
                <Route
                    path="/impressum"
                    component={Imprint}
                />
                <Route
                    path="/karte/:category?"
                    component={MenuPage}
                />
                <Route
                    path="/"
                    component={HomePage}
                />
            </Switch>
            <Footer />
        </Router>
    </div>
);

export default App;

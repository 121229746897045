import logo_transparent from "../assets/logo_transparent.png";
import {Link} from "react-router-dom";

interface HeroImageProps {
    image: string;
    showLogo?: boolean;
}

const HeroImage = (props: HeroImageProps) => (
    <div style={{backgroundImage: `url(${props.image})`}} className="bg-cover h-64 w-full">
        <div className="h-full w-full bg-gradient-to-b from-transparent to-zinc-900 pl-2">
            {props.showLogo && (
                <Link to="/">
                    <img src={logo_transparent} alt="Siu Logo" className="w-16 mx-auto"/>
                </Link>
            )}
        </div>
    </div>
);

export default HeroImage;

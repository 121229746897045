interface Item {
    name: string;
    description?: string;
    price: number;
}

interface CategoryProps {
    name: string;
    subtitle?: string;
    items: Item[];
}

const Category = (props: CategoryProps) => (
    <div className="px-4 py-8">
        <div className="mb-4">
            <span className="text-3xl font-thin mb-1 block">{props.name}</span>
            {props.subtitle && (<span className="text-sm text-gray-100">{props.subtitle}</span>)}
        </div>
        {props.items.map((item) => (
            <div className="flex mb-4" key={item.name}>
                <div className="flex-1">
                    <span className="block uppercase">{item.name}</span>
                    {item.description && (<span className="text-gray-400">{item.description}</span>)}
                </div>
                <div className="text-right">
                    {item.price.toLocaleString(undefined, {style: 'currency', currency: 'EUR'})}
                </div>
            </div>
        ))}
    </div>
);

export default Category;

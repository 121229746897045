import LocationMarker from "../components/LocationMarker";
import barImage from "../assets/images/bar.jpg";
import HeroImage from "../components/HeroImage";
import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import outsideImage from '../assets/images/outside.jpg';
import loungeImage from '../assets/images/lounge.jpg';
import map from '../assets/images/map.png';
import ClockIcon from "../components/icons/ClockIcon";
import MapIcon from "../components/icons/MapIcon";
import {Link} from "react-router-dom";
import InstagramIcon from "../components/icons/InstagramIcon";

const HomePage = () => (
    <div className="bg-zinc-900">
        <HeroImage image={barImage} showLogo/>
        <div className="max-w-4xl mx-auto px-4 pb-5 mt-4">
            <h1 className="text-white text-4xl uppercase font-thin">Siu Buxtehude</h1>
            <p className="mt-6 text-white leading-relaxed">
                Deine Lieblings-Location in <LocationMarker/>. Kühle Drinks und heiße Pfeifen.
            </p>
            <div className="flex justify-end my-4">
                <Link
                    to="/karte"
                    style={{boxShadow: '0 0 10px rgb(91, 33, 182, 0.5)'}}
                    className="bg-violet-900 text-white px-6 py-2 rounded-full flex"
                >
                    <span className="mr-2">
                      Karte ansehen
                    </span>
                    <ArrowRightIcon/>
                </Link>
            </div>
        </div>
        <div className="max-w-4xl mx-auto px-4 py-3">
            <span className="text-xl text-violet-500 flex items-center">
                <InstagramIcon/>
                <span className="ml-2 font-bold">Instagram</span>
            </span>
            <p className="text-white pt-2 tracking-wide leading-9">
                Folge uns:
                <a
                    href="https://www.instagram.com/siu_buxtehude/"
                    className="text-violet-500 ml-1 font-bold"
                >
                    @siu_buxtehude
                </a>
            </p>
        </div>
        <div className="max-w-4xl mx-auto px-4 py-3">
            <span className="text-xl text-violet-500">
                <span className="font-bold">Events</span>
            </span>
            <p className="text-white pt-2 tracking-wide leading-9">
                Top Location für:<br/>
                Geburtstage<br/>
                Firmen-Events<br/>
                Seminare
            </p>
        </div>
        <div className="max-w-4xl mx-auto px-4 py-3">
            <span className="text-xl text-violet-500 flex items-center">
                <ClockIcon/>
                <span className="ml-2 font-bold">Öffnungszeiten</span>
            </span>
            <p className="text-white pt-2 tracking-wide leading-9">
                So-Do: 17 bis 24 Uhr<br/>
                Fr-Sa: 17 bis 04 Uhr
            </p>
        </div>
        <div className="max-w-4xl mx-auto px-4 py-3" id="map">
            <span className="text-xl text-violet-500 flex items-center">
                <MapIcon/>
                <span className="ml-2 font-bold">Location</span>
            </span>
            <p className="text-white py-2 tracking-wide leading-9">
                Bei der Kirche 4-6<br/>
                21614 Buxtehude
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                <figure style={{backgroundImage: `url(${outsideImage})`}}
                        className="max-w-4xl mx-auto bg-cover h-64 w-full rounded-lg bg-center"/>
                <figure style={{backgroundImage: `url(${loungeImage})`}}
                        className="max-w-4xl mx-auto bg-cover h-64 w-full rounded-lg bg-center"/>
            </div>
            <figure style={{backgroundImage: `url(${map})`}}
                    className="mt-4 bg-cover bg-center w-full h-72 rounded-lg"/>
            <div className="flex justify-around text-center my-4 text-zinc-100 gap-4">
                <a
                    href="https://maps.apple.com/?daddr=Siu+Buxtehude"
                    className="bg-zinc-800 flex-1 rounded-xl py-4 font-bold text-violet-500"
                >
                    Apple Karten
                </a>
                <a
                    href="https://www.google.com/maps/dir/?api=1&destination=Siu+Buxtehude"
                    className="bg-zinc-800 flex-1 rounded-lg py-4 font-bold text-violet-500"
                >
                    Google Maps
                </a>
            </div>
        </div>
        
    </div>
);

export default HomePage;

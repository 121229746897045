import Container from "./Container";
import {Link} from "react-router-dom";

const Footer = () => (
    <div className="bg-zinc-900">
        <Container>
            <div className="flex justify-center content-center text-zinc-400 space-x-4 py-4 pb-6">
                <Link to="/karte">Karte</Link>
                <Link to="/impressum">Impressum</Link>
            </div>
        </Container>
    </div>
);

export default Footer;

import logo_transparent from '../assets/logo_transparent.png';
import {PropsWithChildren} from "react";
import EmptyObject from "../utilities/EmptyObject";
import barImage from '../assets/images/bar.jpg';
import {Link} from "react-router-dom";

const Header = (props: PropsWithChildren<EmptyObject>) => (
    <div
        style={{backgroundImage: `url(${barImage})`}}
        className="bg-cover"
    >
        <div className="bg-gradient-to-b from-transparent to-zinc-900">
            <Link to="/" className="flex items-center pl-2 pr-4">
                <img src={logo_transparent} alt="Siu Logo" className="w-16"/>
            </Link>
            <div className="px-4 pt-16 pb-24">
                {props.children}
            </div>
        </div>
    </div>
);

export default Header;

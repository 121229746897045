import HeroImage from "../components/HeroImage";
import barImage from '../assets/images/bar.jpg';
import card from '../assets/example_card.json';
import CategoryPill from "../components/CategoryPill";
import idify from "../utilities/idify";
import {useParams, Link} from "react-router-dom";
import Category from "../components/Category";
import ChevronLeftIcon from "../components/icons/ChevronLeftIcon";

interface MenuPageParams {
    category?: string;
}

const MenuPage = () => {
    const {category: categoryParam} = useParams<MenuPageParams>();

    return (
        <div className="bg-zinc-900 min-h-screen">
            <HeroImage image={barImage} showLogo/>
            <div className="px-4 pb-8 max-w-4xl mx-auto">
                <Link to="/" className="text-violet-500 flex mb-8 mt-4">
                    <ChevronLeftIcon />
                    Zurück zum Start
                </Link>
                <h1 className="text-white text-4xl uppercase font-thin tracking-wide">Karte</h1>
            </div>
            <div
                className=" max-w-4xl mx-auto text-white flex overflow-scroll px-2 relative py-4 border-t border-b border-gray-700">
                <CategoryPill url="/karte" active={categoryParam === undefined}>
                    Alles
                </CategoryPill>
                {card.categories
                    .filter((category) => category.items.length > 0)
                    .map((category) => (
                    <CategoryPill url={`/karte/${idify(category.name)}`}
                                  active={idify(category.name) === categoryParam}>
                        {category.name}
                    </CategoryPill>
                ))}
            </div>

            <div className="text-white  max-w-4xl mx-auto">
                {card.categories
                    .filter((category) => category.items.length > 0 && (categoryParam === undefined || idify(category.name) === categoryParam))
                    .map((category) => (
                        <Category
                            key={category.name}
                            name={category.name}
                            subtitle={category.subtitle}
                            items={category.items}
                        />
                    ))}
            </div>
        </div>
    );
}

export default MenuPage;

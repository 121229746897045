import {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface CategoryPillProps {
    url: string;
    active: boolean;
}

const CategoryPill = (props: PropsWithChildren<CategoryPillProps>) => (
    <Link
        className={classNames('whitespace-nowrap rounded-full mx-2 py-1 px-4 text-sm', props.active && 'bg-violet-800')}
        to={props.url}
    >
        {props.children}
    </Link>
);

export default CategoryPill;
